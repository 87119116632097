import React, {useEffect, useState, useContext} from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import {IoChevronBackCircleSharp} from "react-icons/io5"
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Button from "react-bootstrap/esm/Button";

const Editprofile = () => {
    let {user, profile, getProfile, userdata, authTokens} = useContext(AuthContext)
    const [first_name, setFirst_Name] = (userdata?.length>0?(userdata.first_name):(''))
    const [last_name, setLast_Name] = (userdata?.length>0?(userdata.last_name):(''))
    const [phone_number, setPhone_Number] = (userdata?.length>0?(userdata.phone_number):(''))
    const [next_of_kin, setNext_Of_Kin] = (userdata?.length>0?(userdata.next_of_kin):(''))
    const [state_of_origin, setState_Of_Origin] = (userdata?.length>0?(userdata.state_of_origin):(''))
    const [guarantor, setGuarntor] = (userdata?.length>0?(userdata.guarantor):(''))
    const [former_address, setFormer_Adress] = (userdata?.length>0?(userdata.former_address):(''))
    const [place_of_work, setPlace_Of_Work] = (userdata?.length>0?(userdata.place_of_work):(''))
    const [position_at_work, setPosition_At_Work] = (userdata?.length>0?(userdata.position_at_work):(''))
    useEffect(()=> {
        getProfile();

    },[]

    )
    let formdata = new FormData()
    formdata.append("description", "test");
    let editdata = async ()=>{
        let response = await fetch('https://api.mperial.ng/accounts/update-tenant',{
            method:'PATCH',
            headers:{
                'Content-Type':'Application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            

        }) 
    }
    return(
        <div>
            {first_name}
            <Container>
                    <form className="d-flex align-items-center justify-content-center pt-4 edit-form" >
                    <Row>
                        
                        <Col xs="12">
                        <div>
                            <input className="" type="text" defaultValue={userdata.first_name} placeholder='first_name' onChange={(e)=>setFirst_Name(e.target.value)}/>
                    
                </div>
            
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.last_name} placeholder='last name'/>
                    
                </div>

                        </Col>
        
                        <Col xs="12">
                        <div>
                            <input type="text" placeholder={profile.email}/>
                    
                </div>
                        </Col>

                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.phone_number} placeholder='mobile no'/>
                    
                </div>
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.next_of_kin} placeholder='next of kin'/>
                    
                </div>
                        </Col>

                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.state_of_origin} placeholder='state_of_origin'/>
                    
                </div>
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.guarantor} placeholder='guarantor'/>
                    
                </div>
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.former_address} placeholder='former address'/>
                    
                </div>
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.place_of_work} placeholder='place of work'/>
                    
                </div>
                        </Col>
                        <Col xs="12">
                        <div>
                            <input type="text" defaultValue={userdata.position_at_work} placeholder='position at work'/>
                    
                </div>
                        </Col>
                        <Col>
                        <div>
                            <Button>Update</Button>
                        </div>
                        </Col>
                    </Row>

                    </form>
                   
                </Container>
                
                
        </div>
    )
}


export default Editprofile;