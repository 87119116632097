import React, { useState } from "react";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";

const Example = (event) => {
    let {authTokens, logoutUser} = useContext(AuthContext)
    let {user, profile, getProfile} = useContext(AuthContext)
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('file', event.target.fileInput.files[0]);
    
    fetch('https://api.mperial.ng/api/make-payment', {
        headers:{
            'Content-Type': 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
            'Accept': 'application/json',
            
            
            'Authorization':'Bearer ' + String(authTokens.access),
          
            
        },
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));

    return (
        <form onSubmit={Example}>
          <input type="file" name="fileInput" />
          <button type="submit">Submit</button>
        </form>
      );
  };

  export default Example;
  
  
  