import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { PaystackButton } from 'react-paystack'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {TbCurrencyNaira} from "react-icons/tb"
import AuthContext from "../context/AuthContext";
import {AiOutlinePlus} from "react-icons/ai"
import { json } from "react-router-dom";
import { Button } from "bootstrap";
import axios from 'axios';
import shortid from 'shortid';
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';






const Makepayment = () => {
    const navigate = useNavigate()
    let {authTokens, logoutUser} = useContext(AuthContext)
    let {user, profile, getProfile, paymentdata, getpaymentinfo} = useContext(AuthContext)
    const publicKey = ("pk_test_ae7c9c7255d04c7eb6079db6672b3b28523a4420")
    const [ref_Id, setRef_Id] = useState("")
    const [amount, setAmount] = useState("")
    const onSuccess = (response) => {
      console.log(response.reference);
    };
    const onClose = () => console.log('Payment closed');
    useEffect(()=> {
      getProfile();
      getAccount();
      getpaymentinfo();

  },[]

  )
    
  

    
    const [description, setDescription] = useState("");
    const [receipt, setReceipt] = useState(null);
    const [type, setType] = useState("transfer");
    const [accountdetails, setAccountDetails] = useState([]);
    const ref = shortid.generate();
    let formdata = new FormData()
    formdata.append("description", description);
    formdata.append("amount", amount/100);
    formdata.append("type", type);
    formdata.append("ref_code", ref);
    formdata.append("status", true);
    formdata.append("receipt",  receipt);

    const [paymentData, setPaymentData] = useState({});

  


    
    const email=profile.email
    let redirectpayment = () => {

      navigate('/view-payment')
  }

    let getAccount = async()=>{
      let response = await fetch('https://api.mperial.ng/api/assigned/accounts',{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (authTokens.access)

      }

    })
    let data = await response.json()
    
        if(response.status === 200){
            <p>success</p>
            console.log(data)
            setAccountDetails(data)
            console.log(data.message)
        }else if(response.status === 400){
            <p>failed</p>
            console.log(data)
            console.log(data.message)
        }
    }
    if(accountdetails.length>0){
      console.log(accountdetails[0]['bank_name'])

    }
    
    
    let savePayment = async()=>{
        let response = await fetch('https://api.mperial.ng/api/make-payment', {
            method:'POST',
            headers:{
                //'Content-Type': 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
                //'Accept-Content': 'application/json',
                
                
                'Authorization':'Bearer ' + String(authTokens.access),
              
                
            },
           
            body:formdata
    
        })
        let data = await response.json()
    
        if(response.status === 200){
            <p>success</p>
            console.log(data)
            console.log(data.message)
            console.log(formdata)
            redirectpayment()
        }else if(response.status === 400){
            <p>failed</p>
            console.log(data)
            console.log(data.message)
            console.log(formdata.get('amount'))
            console.log(amount)
        }
    
    }

    let Paystackpayment = async()=>{
      let response = await fetch('https://api.mperial.ng/api/make-payment', {
          method:'POST',
          headers:{
              'Content-Type': 'application/json',
              //'Accept-Content': 'application/json',
              
              
              'Authorization':'Bearer ' + String(authTokens.access),
            
              
          },
         
          body:JSON.stringify({"description":description,"amount":amount, "type":type, "ref_code":ref_Id, "receipt":receipt})
  
      })
      let data = await response.json()
  
      if(response.status === 200){
          <p>success</p>
          console.log(data)
          console.log(data.message)
          alert('payment added successfully')
          redirectpayment()
          
      }else if(response.status != 200){
          <p>failed</p>
          console.log(data)
          console.log(data.message)
          
      }
  
  }
  const handleSuccess = (reference) => {
    console.log('Payment successful. Reference: ' + reference.reference);
    // TODO: save payment reference to database or use it to update payment status
    setRef_Id(reference.reference)
    Paystackpayment();
  };
  

    const pay = (event) => {
      
      {type==='transfer' && receipt?
      (savePayment()):
      alert('Please upload a proof of payment')
    } 
      
     
      event.preventDefault();
    };  
    function isFormValid() {
      // check if all required fields are filled in
      if (!email || !amount || !description) {
        return false;
      }
    
      // add more validation rules here if needed
    
      // if all validation rules pass, return true
      return true;
    }
  
    
    
    
    
  
    return (
      <div className="">
        <div className="payment-containe">
          <div className="">
            <img />
            <div className="">
      
            </div>
          </div>
          <div className="">
            <form encType="multipart/form-data" onSubmit={pay}>
              <Container className="pay">
              <Row>
                <Col xs={12}>
                <label>Description</label>
              
                </Col>
                <Col>
              <input
                type="text"
                id="description" 
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            
                </Col>
              </Row>
              
             
              <Col className="pt-3">
              <Row>
              <label>Amount</label>
              </Row>
              
              
              </Col>
              <Row>
              <Col xs={10}>
              
              <input
                type="number"
                id="quantity"
                onChange={(e) => setAmount(e.target.value*100)}
              width="2rem"
              required
              />
              
              </Col>
              <Col xs={2}>
      <div className="search-box">
      <div className="card" style={{height:'2.8rem', width:'3rem', backgroundColor:'#002F5B'}}>
        <TbCurrencyNaira size="2rem" style={{margin:".3rem"}} color="white"/>
        
      </div>
      </div>
      </Col>
              </Row>
              
              
              
              
              <Row className="pt-3">
                
                <Col>
                <label for="type"></label>
                
                <select name="options" id="option" onChange={(e) => setType(e.target.value)}>
                <option value="transfer" onChange={(e) => setType(e.target.value)}>Transfer</option>
              <option value="online payment" onChange={(e) => setType(e.target.value)}>Online Payment</option>
  
  
</select>
                </Col>
              </Row>
              
              </Container>
              
              <Container>
            <Row>
                <Col>
                {type==='online payment'?
            (<div className="textRight" style={{textAlign:"right"}}>
              <PaystackButton text="Next"
            email={email}
            amount={amount}
            description={description}
            
            callback={onSuccess}
            close={onClose}
            disabled={!isFormValid()}
            embed={false}
            onSuccess={(reference) => handleSuccess(reference)}
            publicKey={publicKey}
            className="paystack-button"
            />

            
            </div>):
            <div className="pt-4">
                
                <Container>
                  <div>
                  <Card className="payment-data" style={{backgroundColor:'#d9d9d9'}}>
                    <Card.Body>
                    <Row>
                      <Col xs="12">
                      <p style={{textAlign:"center"}}>Total amount to be paid: <span style={{color:'red'}}>0 NGN</span></p>
                      </Col>
                    <Col>
                    
                    <p>Payment breakdown</p>
                    <p>Recurring: <span style={{color:'red'}}>{paymentdata.recurring}</span></p>
                    <p>One off: <span style={{color:'red'}}>{paymentdata.one_off}</span></p>
                    <p>Refundable: <span style={{color:'red'}}>{paymentdata.refundable}</span></p>
                  </Col>
                  
                    <Col>
                    <p className="text-center">Please Transfer to the below Account</p>
                    {accountdetails.length>0?(<h5 style={{textAlign:"center", color:"#002F5B"}}>{accountdetails[0]['bank_name']}</h5>):<></>}
                    {accountdetails.length>0?(<h6 style={{textAlign:"center"}}>{accountdetails[0]['account_number']}</h6>):<></>}
            
                    {accountdetails.length>0?(<p style={{textAlign:"center"}}>{accountdetails[0]['account_name']}</p>):<></>}
                
            
                    </Col>
                  </Row>
                    </Card.Body>
                  </Card>
                  </div>
                </Container>
            <Container>
              <Row>
                <Col>
                <p className="pt-3">I Have Made Payment</p>
                </Col>
                <Col>
                <div className="textRight" style={{textAlign:"right"}}>
            
            <input type="file" id="file-input" name="file-input" onChange={(e) => setReceipt(e.target.files[0])}  className="d-none"/>
            <label htmlFor="file-input" className="mb-0 me-2">
              <div className="card">
                <div className="card-body">
                {receipt? <p>Uploaded</p>:<AiOutlinePlus size={13}/>}
                
                
                </div>

                </div>
                {!receipt?<p>Upload</p>:<></>}
                
                

            </label>
            
            </div>
            <div className="textRight" style={{textAlign:"right"}}></div>
                </Col>
              </Row>
            </Container>
            
            
            
            <div style={{textAlign:'right'}}>
              <button type="submit" style={{backgroundColor:"green"}} className="btn-transfer">Next</button>
            </div>
            </div>
            

          

            }
                </Col>
            </Row>
           </Container>
            </form>
           
            
          </div>
        </div>
        
      </div>
    )
  }

export default Makepayment;