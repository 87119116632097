import React, {useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Header from '../components/Header';
import SearchPage from  '../components/searchbar';
import Footer from './footer.component';
import tenants from './tenants.png'

const Home = ()=> {
       let [notes, setNotes] = useState([])
       let {authTokens, logoutUser} = useContext(AuthContext)
        useEffect(()=> {
            getNotes();

        },[]

        )
        let getNotes = async()=>{
            let response = await fetch('https://api.mperial.ng/api/tenant/details', {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access) 
                }

            })
            let data = await response.json()

            if(response.status === 200){
                setNotes(data)
            }else if(response.statusText === 'Unauthorized'){
                logoutUser()
            }

        }
       return(
        <div>
            <div className='home-wrapper'>
            <Header/>
            <br></br>
            <h1></h1>
            <div className='home-inner pt-4'>
                
            <p></p>
            

           
<ul>

</ul>
<div style={{marginTop:'8rem'}} className="d-flex align-items-center justify-content-center">
    
    <img src={tenants} style={{height:'4rem;', width:'4rem'}}/>
    
</div>
<div className='pt-3'>
<p className='text-center'></p>

</div>


            </div>


            
            
        </div>
        <Footer/>
        </div>
        
       )
    }
export default Home;
