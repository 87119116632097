import React,{useState, useEffect, useContext} from "react"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import AuthContext from "../context/AuthContext"
import { MdOutlineReceiptLong } from "react-icons/md";
import {IoChevronBackCircleSharp} from "react-icons/io5";
import { Link } from "react-router-dom"
import Footer from "./footer.component"

const ViewPayment = () => {
    let [payments, setPayments] = useState([])
       let {authTokens, logoutUser} = useContext(AuthContext)
        useEffect(()=> {
            getPayments();

        },[]

        )
        let getPayments = async()=>{
            let response = await fetch('https://api.mperial.ng/api/tenant/view-payment', {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access) 
                }

            })
            let data = await response.json()
            {console.log(data)}

            if(response.status === 200){
                setPayments(data)
            }else if(response.statusText === 'Unauthorized'){
                logoutUser()
            }


        }
        const tests=[{'name':'test'}]
        return(
            <div className="pt-3">
                <Container>
                    <Row>
                        <Col xs="12">
                        <div>
                    <p style={{textAlign:"left"}}><Link className="text-link" to="/payment"><IoChevronBackCircleSharp size="2rem" color="#002F5B"/> View Payment</Link></p>
                </div>
                        </Col>
                    </Row>
                    <Footer></Footer>
                </Container>

                <div className='home-inner file-inner pt-4'>  

{
    payments?.length>0?(payments.map((payment=>(
          
            
        <Container className="card pt-3">
            
            <Row className="pt-3">
                <Col xs={1}>
                <p style={{textAlign:'left'}}><MdOutlineReceiptLong size="4rem" className="" color="#002F5B"/></p> 
                 
                </Col>
                <Col>
                <p style={{textAlign:'center'}}>{payment.description}</p>
                {payment.status?(<p style={{textAlign:'center', color:'green'}}>Approved
            </p>): (<p style={{textAlign:'center' , color:'red'}}>Pending</p>)}
                </Col>
                <Col>
                <p style={{textAlign:'right', color:'green'}}>{payment.amount} NGN</p>
                <p style={{textAlign:'right'}}>{payment.payment_date_and_time.substr(0,10)}</p>

                </Col>
                
            </Row>
            
            
            
        </Container>

    


  
)))):
(<div className="card" style={{borderHeight:'3rem'}}> Empty</div>)
}
<br></br>
        
      
               </div>

           <Footer/>  
            </div>
        )
}
export default ViewPayment;