import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { TbReceipt } from "react-icons/tb";
import { CgFileDocument } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { MdSystemSecurityUpdate } from "react-icons/md";
import { Button } from "bootstrap";
import {RiHomeLine} from "react-icons/ri"

const Footer = ()=> {
    return (
        <Container className="fixed-bottom" style={{backgroundColor:"#002F5B", marginLeft:"0px", marginRight:"0px", maxWidth:"100%"}}>
            <div className="">
                <div className="">
                <Row>
                <Col>
               

                <Link to="/"><RiHomeLine size="1.4em" color="white" paddingbottom=""/></Link>
                </Col>
                <Col>
                <Link to="/payment"><TbReceipt size="1.4em" color="white"/></Link>
                </Col>

                <Col>
                <Link to="/document"><CgFileDocument size="1.4em" color="white"/></Link>
                </Col>
                <Col>
               <Link to="/profile"><FaRegUser size="1.4em" color="white"/></Link>
                </Col>

            </Row>

                </div>

            </div>
        </Container>
    )
}


export default Footer