import React, {useContext, useEffect} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchPage from  '../components/searchbar';

const Header = () => {
    let {user, logoutUser, profile,userdata, getProfile} = useContext(AuthContext)
    useEffect(()=>{

    getProfile();
    },[]
    )
    
    return (
        <div className='heading-wrap pt-4'>
            
            <Container className='heading d-block justify-content-center px-4'>
            <Row>
            <Col>
            {user &&   <h6>Hello ,
            </h6>
    
            }
            {user && 
            <h6>{userdata.first_name}</h6>
            
            }
            <p></p>

            </Col>
            
            </Row>
            <Col>
            <SearchPage/>
            </Col>
        </Container>
        </div>
           

           
          
    
    )
}

export default Header
