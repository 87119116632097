import React,{useState, useEffect, useContext} from "react"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { Button } from "bootstrap"
import AuthContext from "../context/AuthContext"
import { TbReceipt } from "react-icons/tb";
import {RiDeleteBinLine} from "react-icons/ri"
import {BsFillFileEarmarkFill} from "react-icons/bs"
import { Link } from "react-router-dom"
import { GoDiffAdded } from "react-icons/go"
import {AiOutlinePlus} from "react-icons/ai"
import Footer from "./footer.component"
import {IoChevronBackCircleSharp} from "react-icons/io5"
import { Navigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"

const MyFile = () => {
    const navigate = useNavigate()
    let [files, setFiles] = useState([])
    let [fileName, setFileName] = useState([])
    let[filedata, setFiledata] = useState()
       let {authTokens, logoutUser} = useContext(AuthContext)
        useEffect(()=> {
            getFiles();

        },[]

        )
        let formdata = new FormData()
        console.log(filedata)
        formdata.append('document', filedata);
        formdata.append('name', fileName);

        let uploadFile = async()=>{
            let response = await fetch('https://api.mperial.ng/api/tenant/add-document', {
          method:'POST',
          headers:{
              //'Content-Type': 'application/json',
              //'Accept-Content': 'application/json',
              
              
              'Authorization':'Bearer ' + String(authTokens.access),
            
              
          },
         
          body:formdata
  
      })
      let data = await response.json()
  
      if(response.status === 200){
          console.log(data)
          console.log(data.message)
          
      }else if(response.status === 400){
          console.log(data)
          console.log(data.message)
          
      }
  
  }
  let redirectupload = () => {

    navigate('/my-file')
}

{filedata?(uploadFile()):<></>}


        let getFiles = async()=>{
            let response = await fetch('https://mperial.techevery.ng/api/tenant/my-files', {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access) 
                }

            })
            let data = await response.json()
            {console.log(data)}

            if(response.status === 200){
                setFiles(data)
            }else if(response.statusText === 'Unauthorized'){
                logoutUser()
            }


        }
        const tests=[{'name':'test'}]
        return(
            <div className="pt-3">
                <Container>
                    <Row>
                        <Col xs="12">
                        <div>
                    <p style={{textAlign:"left"}}><Link className="text-link" to="/document"><IoChevronBackCircleSharp size="2rem" color="#002F5B"/> View File</Link></p>
                </div>
                        </Col>
                    </Row>
                   
                </Container>



               
<div className='home-inner file-inner pt-4'>

    

{files?.length>0?(
    
    files.map((file=>(
      
        
            <Container className="card pt-0" >
                
                <Row className="pt-2">
                    <Col xs={3}>
                    <p style={{textAlign:'left', color:'red'}}><BsFillFileEarmarkFill size="4rem" className=""/></p> 
                     
                    </Col>
                    <Col>
                    <p style={{textAlign:'center'}} className="pt-3">{file.name}</p>
                    
                    
                    </Col>
                    <Col>
                    <p style={{textAlign:'right', color:''}} className="pt-3"><RiDeleteBinLine/></p>
                    

                    </Col>
                    
                </Row>
                
                
                

        
            </Container>
            
            

        


      
    )))
  
):
(<div className="" style={{borderHeight:'3rem'}}> No Documents</div>)
}
<br></br>

<input type="file" id="file-input" className="d-none" onChange={(e) => (setFiledata(e.target.files[0]),setFileName(e.target.files[0].name))}/>
  <label htmlFor="file-input" className="mb-0 me-2">
  <div className='card pt-3' style={{width:'32rem'}}>
                <div className='card-body'>
                    <div className='card-title text-center'>
                    
                    <AiOutlinePlus size={20}/>
                    </div>

                </div>
                

            </div>
    
  </label>
                
            
            

           
            

            

            </div>
            


            <Footer/>
            </div>


        
        );
}
export default MyFile;