import React,{useState, useEffect, useContext} from "react"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import AuthContext from "../context/AuthContext"
import { TbReceipt } from "react-icons/tb";
import {RiDeleteBinLine} from "react-icons/ri"
import {BsFillFileEarmarkFill} from "react-icons/bs"
import { Link } from "react-router-dom"
import { GoDiffAdded } from "react-icons/go"
import {AiOutlinePlus} from "react-icons/ai"
import {IoChevronBackCircleSharp} from "react-icons/io5"
import Footer from "./footer.component"

const ViewFile = () => {
    let [files, setFiles] = useState([])
       let {authTokens, logoutUser} = useContext(AuthContext)
        useEffect(()=> {
            getFiles();

        },[]

        )
        let getFiles = async()=>{
            let response = await fetch('https://api.mperial.ng/api/tenant-files', {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access) 
                }

            })
            let data = await response.json()
            {console.log(data)}

            if(response.status === 200){
                setFiles(data)
            }else if(response.statusText === 'Unauthorized'){
                logoutUser()
            }


        }
        const tests=[{'name':'test'}]
        return(
            <div className="pt-3">
                <Container>
                    <Row>
                        <Col xs="12">
                        <div>
                    <p style={{textAlign:"left"}}><Link className="text-link" to="/document"><IoChevronBackCircleSharp size="2rem" color="#002F5B"/> View Payment</Link></p>
                </div>
                        </Col>
                    </Row>
                   
                </Container>


               
<div className='home-inner file-inner pt-4'>
    

{files?.length>0?(
    
    files.map((file=>(
      
        
            <Container className="card pt-0" >
                
                <Row className="pt-2">
                    <Col xs={3}>
                    <p style={{textAlign:'left', color:'red'}}><BsFillFileEarmarkFill size="4rem" className=""/></p> 
                     
                    </Col>
                    <Col>
                    <p style={{textAlign:'center'}} className="pt-3">{file.name}</p>
                    
                    
                    </Col>
                    <Col>
                    <p style={{textAlign:'right', color:''}} className="pt-3"><RiDeleteBinLine/></p>
                    

                    </Col>
                    
                </Row>
                
                
                

        
            </Container>
            
            

        


      
    )))
  
):
(<div className="card" style={{borderHeight:'3rem'}}> Empty</div>)
}
<br></br>


                
            
            

           
            



            </div>
            


<Footer/>
            </div>


        
        );
}
export default ViewFile;