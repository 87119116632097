import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { TbReceipt } from "react-icons/tb";
import { CgFileDocument } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { MdSystemSecurityUpdate } from "react-icons/md";



//import { MDBCol } from "mdbreact";

const SearchPage = () => {
  return (
    
      <Container className="pt-3">
        <Row style={{textAlign:'center'}}>
      <Col>
      <div className="active-pink-3 active-pink-4 mb-4">
        <input className="form-control" type="text" placeholder="Search for update" aria-label="Search" />
      </div>
      </Col>
      <Col xs={2}>
      <div className="search-box">
      <div className="card" style={{height:'2.8rem', width:'3rem', backgroundColor:'#002F5B'}}>
        
      </div>
      </div>
      </Col>
      </Row>
      
  
      
      <Row style={{textAlign:'center'}}>
      <Col>
      <Link to="/" >
      <div className="icon">
        <MdSystemSecurityUpdate size="2em"/>
        
        
      </div>
      </Link>
      
      <div className="icon-text">
      <p style={{marginLeft:'-.2rem'}}>Update</p>

      </div>
      
      </Col>

      <Col>
      <Link to="/payment">
      <div className="icon">
      <TbReceipt size="2em"/>
        
      </div>
      </Link>
      <div className="icon-text">
      <p style={{marginLeft:'-.4rem'}}>Payment</p>
      </div>
      
      </Col>

      <Col>
      <Link to="/document">
      <div className="icon">
      <CgFileDocument size="2em"/>
      </div>
      </Link>
      <div className="icon-text">
      <p style={{marginLeft:'-1rem'}}>Documents</p>
      </div>
      
      </Col>
      <Col>
      <Link to="/profile">
      <div className="icon">
        <FaRegUser size="2em"/>
      </div>
      </Link>
      <div className="icon-text">
      <p style={{textAlign:''}}>Profile</p>
      </div>
      </Col>

      

      </Row>

      </Container>

      
      
     
   
  );
}

export default SearchPage;