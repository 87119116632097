import React, {useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Header from '../components/Header';
import SearchPage from  '../components/searchbar';
import Card from 'react-bootstrap/Card';
import { GoDiffAdded } from "react-icons/go";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Footer from './footer.component';
import Makepayment from './makepayment.component';

const Payment = ()=> {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
       let [notes, setNotes] = useState([])
       let {authTokens, logoutUser} = useContext(AuthContext)
        useEffect(()=> {
            getNotes();

        },[]

        )
        let getNotes = async()=>{
            let response = await fetch('https://api.mperial.ng/api/tenant/details', {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access) 
                }

            })
            let data = await response.json()

            if(response.status === 200){
                setNotes(data)
            }else if(response.statusText === 'Unauthorized'){
                logoutUser()
            }

        }
       return(
        <div>
            <div className='home-wrapper'>
            <Header/>
            <br></br>
            <h1></h1>
            <div className='home-inner payment-inner pt-4'>
                
            <div className='card'>
                <Link className='text-link' onClick={handleShow}>
                <div className='card-body'>
                    <div className='card-title text-center'>
                    <p> <GoDiffAdded size="1.3rem"/> Make Payment</p>

                    </div>

                </div>
                </Link>

            </div>
            

            <div className='pt-2'>
            <div className='card'>
                <Link className='text-link' to="/view-payment" >
                <div className='card-body'>
                    <div className='card-title text-center'>
                        <p>View Payment</p>

                    </div>

                </div>
                </Link>

            </div>
            </div>
            



            </div>


            

      <Modal show={show} onHide={handleClose} >
        <Makepayment/>
      </Modal>

     
        </div>
        <Footer/>
        </div>
        
       )
    }
export default Payment;