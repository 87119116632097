import React, {useEffect, useState, useContext } from 'react';
import { renderMatches } from 'react-router-dom';
import Footer from './footer.component';
import AuthContext from '../context/AuthContext';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import user3 from './user3.png'
import {MdEdit} from 'react-icons/md'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';



const Profile = () => {
    let {user, profile, getProfile, userdata, authTokens, logoutUser} = useContext(AuthContext)
    let [name, setName] = useState('')
    let [managerDetails, setManagerDetails] = useState([])
    useEffect(()=> {
        getProfile();
        getManager();

    },[]

    ) 
    let getManager = async()=>{
        let response = await fetch('https://mperial.techevery.ng/api/tenant/manager',{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + (authTokens.access)
  
        }
  
      })
      let data = await response.json()
      
          if(response.status === 200){
              <p>success</p>
             
              setManagerDetails(data.data)
              
          }else if(response.status === 400){
              <p>failed</p>
              console.log(data)
              console.log(data.message)
          }
      }

    
   
    
    
    
    
    

   
    
    
    
    

    
   
    return(
        <div className=''>

            <Container>
                <Row>
                    <div className='fixed-top'>
                    <p style={{textAlign:'right'}}>ACTIVE</p>

                    </div>
                </Row>
            </Container>


            <Container className='pt-4'>
                <Row className=''>
                    <Col>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='' style={{width:'12rem', borderRadius:'50%', backgroundColor:'#002F5BA1'}}>
                            <div className='card-body'>
                                <div className='card-img'>
                                <img src={user3}  style={{ width: '10rem',}}/>
                                <p className='' style={{textAlign:'right'}}><Link to=""><MdEdit size={25}/></Link></p>

                                </div>

                            </div>

                        </div>
                    
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>First Name</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.first_name}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Last Name</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.last_name}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Email</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{profile.email}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Phone number</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.phone_number}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Next of kin</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.next_of_kin}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>State of origin</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.state_of_origin}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Guarantor</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.guarantor}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Former Address</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.former_address}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Place of work</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.place_of_work}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Position at work</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}} className='border-bottom'>{userdata.position_at_work}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Annual salary</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}}>{userdata.annual_salary}</p></Col>
                </Row>
                <Row>
                    <Col xs={12}><p style={{textAlign:'left'}}>Purpose of rent</p></Col>
                    <Col xs={12}><p style={{textAlign:'left', color:'#7f7d7d'}}>{userdata.purpose_of_rent}</p></Col>
                </Row>

                
                
            </Container>

            <Container className=''>
            <Row className=''>
                    <Col>
                    <div className="card">
                        <div className='card-body bot'>
                            <p>{managerDetails.first_name} {" "}  {managerDetails.last_name}</p>
                            <p style={{color:'#7f7d7d'}}>{managerDetails.phone_number}</p>
                            {console.log(managerDetails)}

                        </div>

                    </div>
                    </Col>
                    <Col className="d-none d-md-block">
                    <div className="card">
                        <div className='card-body bot'>
                            <p></p>

                        </div>

                    </div>
    </Col> 
                    <Col>
                    <div className="card">
                        <div className='card-body bot'>
                            <p>{profile.flat_name}</p>

                        </div>

                    </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                    <div className='text-center profile-btn pt-3'>
                        <Button style={{}} className=''>Change Password</Button>
                        
                        

                    </div>
                    </Col>
                </Row>
                <Row>
                <div className='text-center logout-btn pt-2'>
                        <Button style={{}} className='' onClick={logoutUser}>Log Out</Button>
                        
                        

                    </div>
                </Row>
                <br></br>
            </Container>

            
           
                    
            <Footer/>

        </div>
    )
}

export default Profile