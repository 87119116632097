import React, { Component } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './components/login.component';
import SignUp from './components/signup.component';
import Home from './components/home.component';
import Payment from './components/payment.component';
import ViewPayment from './components/viewpayment.component';
import Document from './components/document.component';
import PrivateRoute from './utils/PrivateRoute';
import Form from 'react-bootstrap/Form';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';
import ViewFile from './components/viewfile.component';
import Footer from './components/footer.component';
import MyFile from './components/myfile.component';
import Makepayment from './components/makepayment.component';
import Example from './components/test.component';
import Profile from './components/profile.component';
import Editprofile from './components/editprofile.component';



const App = () => {

  return(
     
   






    
      <div className="App">
       {/* } <nav className="navbar navbar-expand-lg navbar-light fixed-top">
          <div className="container">
            <Link className="navbar-brand" to={'/sign-in'}>
              positronX
            </Link>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={'/sign-in'}>
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={'/sign-up'}>
                    Sign up
                  </Link>
                </li>
              </ul>
            </div>
          </div>
</nav> */}
      <Router>
      <AuthProvider>
            <Routes>
              
              <Route path="/login" element={<Login />} />
              <Route path="/sign-in" element={<Login />} />
              <Route path="/" element={<PrivateRoute> <Home/>
              </PrivateRoute>} />
              <Route path="/payment" element={<PrivateRoute> <Payment/>
              </PrivateRoute>} />
              <Route path="/view-payment" element={<PrivateRoute> <ViewPayment/>
              </PrivateRoute>} />
              <Route path="/document" element={<PrivateRoute> <Document/>
              </PrivateRoute>} />
              <Route path="/view-file" element={<PrivateRoute> <ViewFile/>
              </PrivateRoute>} />
              <Route path="/my-file" element={<PrivateRoute> <MyFile/>
              </PrivateRoute>} />
              <Route path="/make-payment" element={<PrivateRoute> <Makepayment/>
              </PrivateRoute>} />
              <Route path="/example" element={<PrivateRoute> <Example/>
              </PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute> <Profile/>
              </PrivateRoute>} />
              <Route path="/profile/edit" element={<PrivateRoute> <Editprofile/>
              </PrivateRoute>} />
              
              
              
              
          
            </Routes>
            
            </AuthProvider>

    </Router> 
    
         
        
        </div>


      





      
    
   
    );

}
export default App;
